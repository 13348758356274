/**
 * External Dependencies
 */
 import React, { Component } from 'react';
 import { io } from "socket.io-client";
 import settings from '../settings';


 var api_url = settings.api_url_phase_2



 const socket =  io(api_url, {transport : ['WebSocket']})

 export default socket;
